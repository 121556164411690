import React, { useContext } from 'react'
import { Select as _Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { GlobalStateContext } from '../../../contexts/GlobalStateContext'
import { DashboardMachineState } from '../../../machines/dashboardMachine/dashboardMachine'
import { useSelector } from '@xstate/react'
import { AttributionType, AttributionWindow } from '../../../__generated__/graphql'

const Select = styled(_Select)`
  .ant-select-selector {
    border-radius: 12px !important;
  }
`

const { Option } = Select


interface AttributionOption {
  label: string
  window: AttributionWindow
  type: AttributionType
}

const tooltipContent =
  'Facebook may not support First Click attribution, but FoxTrax pixel metrics do. Facebook metrics requiring attribution will show no data, but other metrics like spend will still appear.'

const attributionOptions: AttributionOption[] = [
  { label: '7 day last click', window: AttributionWindow.SevenDay, type: AttributionType.LastClick },
  { label: '1 day last click', window: AttributionWindow.OneDay, type: AttributionType.LastClick },
  { label: '7 day first click', window: AttributionWindow.SevenDay, type: AttributionType.FirstClick },
  { label: '1 day first click', window: AttributionWindow.OneDay, type: AttributionType.FirstClick },
]

const attributionSettingSelector = (state: DashboardMachineState) => state.context.attributionSetting

export const AttributionSelect: React.FC = () => {
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService

  const attributionSetting = useSelector(globalServices.dashboardService, attributionSettingSelector)
  const setAttributionSetting = (attributionSetting: { attributionWindow: AttributionWindow; attributionType: AttributionType }) => send({ type: 'SET_ATTRIBUTION_SETTINGS_EVENT', attributionSetting })

  const handleChange = (value: string) => {
    const newOption = attributionOptions.find((option) => option.label === value)
    if (newOption) {
      setAttributionSetting({ attributionWindow: newOption.window, attributionType: newOption.type })
    }
  }

  const currentLabel =
    attributionOptions.find((option) => option.window === attributionSetting.attributionWindow && option.type === attributionSetting.attributionType)?.label || attributionOptions[0].label

  return (
    <Select size="large" value={currentLabel} onChange={handleChange as any} style={{ width: 200 }}>
      {attributionOptions.map((option) => (
        <Option key={option.label} value={option.label}>
          {option.label}
          {option.type === AttributionType.FirstClick && (
            <Tooltip title={tooltipContent} placement="right">
              <InfoCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
            </Tooltip>
          )}
        </Option>
      ))}
    </Select>
  )
}
