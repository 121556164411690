import React, { useContext } from 'react'
import { Table, Space, Typography } from 'antd'
import { ColumnGroupType, ColumnType } from 'antd/es/table/interface'
import { colorTextTertiary } from '../../theme/colors'
import { formatNumber } from '../../common/utils/formatter'
import styled from 'styled-components'
import { TableRow } from './AttributionTable'
import { AttributionTableTabs, RowKey } from '../../machines/dashboardMachine/dashboardMachine'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { useSelector } from '@xstate/react'
import { dataSelector } from '../../utils/dashboard-util'
import { pluralize } from '../../utils/pluralize'

const { Text: _Text } = Typography
const { Summary } = Table

const SummarySpace = styled(Space)`
  gap: 0px !important;
`
const Cell = styled(Summary.Cell)<{ footerbackground: string }>`
  background: ${(props) => props.footerbackground} !important;
`

const FixedCell = styled(Cell)`
  z-index: 20 !important;
`

const SummaryText = styled(_Text)`
  font-size: 14px;
  font-weight: 600;
`

const SummaryMetric = styled(_Text)`
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SummaryLabel = styled(_Text)`
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TableSummary = (columns: (ColumnGroupType<TableRow> | ColumnType<TableRow>)[], selectedRowKeys: RowKey[], title: string) => {
  const globalServices = useContext(GlobalStateContext)
  const totals = useSelector(globalServices.dashboardService, dataSelector)

  let currentTab = AttributionTableTabs.CAMPAIGNS
  let tabName = 'Campaign'
  const totalCount = useSelector(globalServices.dashboardService, (state) => {
    currentTab = state.context.tab
    switch (currentTab) {
      case AttributionTableTabs.CAMPAIGNS:
        tabName = 'Campaign'
        return state.context.data.campaignsQuery?.total ?? 0
      case AttributionTableTabs.ADSETS:
        tabName = 'Adset'
        return state.context.data.adsetsQuery?.total ?? 0
      case AttributionTableTabs.ADS:
        tabName = 'Ad'
        return state.context.data.adsQuery?.total ?? 0
      default:
        return 0
    }
  })

  const formattedTotals = {
    dailyBudget: `$${formatNumber(totals?.dailyBudget ?? 0)}`,
    spend: `$${formatNumber(totals?.spend ?? 0)}`,
    orders: formatNumber(totals?.orders ?? 0, 0),
    sourceOrders: formatNumber(totals?.sourceOrders ?? 0, 0),
    ordersValue: `$${formatNumber(totals?.ordersValue ?? 0)}`,
    sourceOrdersValue: `$${formatNumber(totals?.sourceOrdersValue ?? 0)}`,
  }

  const count = selectedRowKeys.length || Math.floor(totalCount)
  const summaryTitle = `Results from ${count.toLocaleString()} ${selectedRowKeys.length ? 'selected' : ''} ${pluralize(tabName, count)}`

  return (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <FixedCell footerbackground={'#FCFCFC'} align="center" colSpan={2} index={0}>
          <SummaryText>{summaryTitle}</SummaryText>
        </FixedCell>
        <Cell footerbackground={'#FCFCFC'} align="center" index={2}></Cell>
        {columns.slice(2).map((column, index) => {
          const key = column.key as keyof typeof formattedTotals
          return (
            <Cell key={key} footerbackground={'#FCFCFC'} align="center" index={index + 2}>
              <SummarySpace direction="vertical">
                <SummaryMetric>{key in formattedTotals ? formattedTotals[key] : '-'}</SummaryMetric>
                <SummaryLabel style={{ color: colorTextTertiary }}>{`Total ${column.title}`}</SummaryLabel>
              </SummarySpace>
            </Cell>
          )
        })}
      </Table.Summary.Row>
    </Table.Summary>
  )
}
