import { AppSider } from '../../components/AppSider';
import { AppHeader } from '../../components/AppHeader/AppHeader';
import { ProfileAction } from '../../components/HeaderActions/Profile/Profile';
import Card from 'antd/es/card/Card';
import { Layout } from 'antd';
import { SupportSettingsForm } from '../../components/SupportSettingsForm';





export const SupportSettings = () => {
    return (
        <Layout style={{ minHeight: '100vh' }} hasSider>
            <AppSider primaryPageKey='support' />
            <Layout className="site-layout">
                <AppHeader title="Need Help?" actions={<ProfileAction />} />
                <Card style={{ margin: '24px' }} >
                    <Layout style={{ width: '100%', backgroundColor: 'white' }} hasSider>
                        <SupportSettingsForm />
                    </Layout>
                </Card>
            </Layout>
        </Layout>
    );
};
