import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App as AntApp, ConfigProvider, Layout, theme } from "antd";
import Router from './Router';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import { colorPrimary } from "./theme/colors";
import { JwtProvider } from './providers/jwtProvider';
import { inspect } from '@xstate/inspect'
import { useLocalStorage } from './hooks/useLocalStorage'
import { calcScreenSizes } from './common/utils/calcBreakpoints'
import { LocalStorageKeys, LocalStorageTypes } from './common/types/localStorage';
import { localStorageSetItem } from './services/storage';

if (import.meta.env.DEV) {
  inspect({
    // url: 'https://statecharts.io/inspect',
    url: "https://stately.ai/viz?inspect",
    iframe: false,
  })
}

const App = () => {
  const [siderCollapse] = useLocalStorage(LocalStorageKeys.SiderCollapse, LocalStorageTypes.Boolean, false)
  const [isMobile] = useLocalStorage(LocalStorageKeys.IsMobile, LocalStorageTypes.Boolean, false)
  const handleResize = () => {
    localStorageSetItem(LocalStorageKeys.IsMobile, (window.outerWidth < 576).toString())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <React.StrictMode>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          components: {
            Layout: {
              colorBgBase: '#F4F4F4', 
            },
          },
          token: {
            colorPrimary,
            ...calcScreenSizes(isMobile, siderCollapse),
          },
        }}
      >
        <AntApp>
          <JwtProvider>
            <ApolloProvider client={client}>
                <Layout>
                  <Router />
                </Layout>
            </ApolloProvider>
          </JwtProvider>
        </AntApp>
      </ConfigProvider>
    </React.StrictMode>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
