import { Tag } from 'antd'
import { ColumnGroupType, ColumnType } from 'antd/es/table/interface'
import { TableRow } from '../AttributionTable'
import { RowKey } from '../../machines/dashboardMachine/dashboardMachine'

export const getColumns = (showDailyBudget = true, onNameClick?: (key: RowKey) => void): (ColumnGroupType<TableRow> | ColumnType<TableRow>)[] => [
  {
    ellipsis: true,
    width: 240,
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    align: 'left',
    render: (text: string, record: TableRow) => {
      return (
        <span onClick={() => onNameClick && onNameClick(record.key)}>
          <a>{text}</a> <span style={{ display: 'block' }}>{record.id}</span>
        </span>
      )
    },
  },
  {
    ellipsis: true,
    width: 140,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (text: string) => {
      let color = ''
      if (text === 'Active') {
        color = 'green'
      } else if (text === 'Paused') {
        color = 'grey'
      }
      return <Tag color={color}>{text}</Tag>
    },
  },
  ...(showDailyBudget
    ? [
        {
          ellipsis: true,
          width: 140,
          title: 'Budget',
          dataIndex: 'budget',
          key: 'dailyBudget',
          align: 'center' as const,
          render: (text: number) => {
            return text !== null && text !== undefined ? `$${text.toLocaleString('en-US')} (Daily)` : '-'
          },
        },
      ]
    : []),
  {
    ellipsis: true,
    width: 140,
    title: 'Spend',
    dataIndex: 'spend',
    key: 'spend',
    align: 'center',
    render: (text: number) => {
      return text !== null && text !== undefined ? `$${text.toLocaleString('en-US')}` : '-'
    },
  },
  {
    ellipsis: true,
    width: 165,
    title: 'Orders (FoxTrax)',
    dataIndex: 'orders',
    key: 'orders',
    align: 'center',
    render: (text: number) => {
      return text !== null && text !== undefined ? text.toLocaleString('en-US') : '-'
    },
  },
  {
    ellipsis: true,
    width: 165,
    title: 'Orders (Facebook)',
    dataIndex: 'sourceOrders',
    key: 'sourceOrders',
    align: 'center',
    render: (text: number) => {
      return text !== null && text !== undefined ? text.toLocaleString('en-US') : '-'
    },
  },
  {
    ellipsis: true,
    width: 170,
    title: 'Revenue (FoxTrax)',
    dataIndex: 'ordersValue',
    key: 'ordersValue',
    align: 'center',
    render: (text: number) => {
      return <div style={{ paddingRight: 8 }}>{text !== null && text !== undefined ? `$${text.toLocaleString('en-US')}` : '-'}</div>
    },
  },
  {
    ellipsis: true,
    width: 170,
    title: 'Revenue (Facebook)',
    dataIndex: 'sourceOrdersValue',
    key: 'sourceOrdersValue',
    align: 'center',
    render: (text: number) => {
      return <div style={{ paddingRight: 8 }}>{text !== null && text !== undefined ? `$${text.toLocaleString('en-US')}` : '-'}</div>
    },
  },
]
